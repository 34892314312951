import { useAuthStore } from "../stores/authStore";

// Guard para proteger las rutas con autenticacion
export const isAuthenticatedGuard = (to, from, next) => {
    try {
        const authStore = useAuthStore();
        if(authStore.isTokenValid()){
            next();
        }else{
            // next({name: 'login', query: { redirect: to.fullPath }});
            next({name: 'login'});
        }
    } catch (error) {
        // next({name: 'login', query: { redirect: to.fullPath }});
        next({name: 'login'});
    }
}