<template>
    <div :class="containerClass" @click="onDocumentClick">
        <div class="layout-wrapper-content">
            <AppTopBar
                :topbarMenuActive="topbarMenuActive"
                :profileMode="profileMode"
                :horizontal="layoutMode === 'horizontal'"
                :activeTopbarItem="activeTopbarItem"
                @menubutton-click="onMenuButtonClick"
                @topbar-menubutton-click="onTopbarMenuButtonClick"
                @topbar-item-click="onTopbarItemClick"
            ></AppTopBar>

            <transition name="layout-menu-container">
                <div :class="menuClass" @click="onMenuClick" v-show="isMenuVisible()">
                    <div class="menu-scroll-content">
                        <AppInlineProfile v-if="profileMode === 'inline' && layoutMode !== 'horizontal'" @profile-click="onProfileClick" :expanded="profileExpanded"></AppInlineProfile>

                        <AppMenu :model="programaId === null ? menuAdministrativo: menuDocentes" :layoutMode="layoutMode" :active="menuActive" :mobileMenuActive="staticMenuMobileActive" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" ></AppMenu>
                    </div>
                </div>
            </transition>

            <div class="layout-main">
                <div v-if="nombrePrograma !== null" class="grid mt-2">
                    <div class="col-11">
                        <h3>{{ nombrePrograma }}</h3>
                    </div>
                    <div class="col-1">
                        <Button
                            @click="volverMenuAdministrativo"
                            label="Volver"
                            class="p-button p-button-danger"
                        />
                    </div>
                </div>
                <router-view />
            </div>

            <!-- <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" :darkMenu="darkMenu" @menu-color-change="onMenuColorChange"
						:profileMode="profileMode" @profile-mode-change="onProfileModeChange" :layout="layoutColor" :layouts="layoutColors" @layout-color-change="onLayoutColorChange"
						:theme="theme" :themes="themeColors" @theme-change="onThemeChange"  :layoutScheme='layoutScheme' @layout-scheme-change='changeLayoutScheme'></AppConfig> -->

            <div class="layout-mask"></div>

            <AppFooter />
        </div>
    </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
// import AppConfig from './AppConfig.vue';
import AppInlineProfile from './AppInlineProfile.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';
import EventBus from './event-bus';
import { useAuthStore } from './stores/authStore';
import Button from 'primevue/button';

export default {
    setup() {
        const authStore = useAuthStore();
        return {
            authStore,
        };
    },
    props: {
        theme: String,
        layoutColor: String,
        layoutScheme: String,
        darkMenu: Boolean,
    },
    data() {
        const itemsAdmin = [
            {
                permiso: 'panel.menu',
                label: 'Panel de Control',
                icon: 'pi pi-fw pi-desktop',
                to: '/',
            },
            {
                permiso: 'programa.menu',
                label: 'Programas',
                icon: 'pi pi-fw pi-align-left',
                items: [
                    {
                        permiso: 'programa.create',
                        label: 'Registrar Programa',
                        icon: 'pi pi-fw pi-file',
                        to: '/programas/registrar',
                    },
                    {
                        permiso: 'programa.list',
                        label: 'Lista Programas',
                        icon: 'pi pi-fw pi-list',
                        to: '/programas/lista',
                    },
                ],
            },
            {
                permiso: 'plantilla.menu',
                label: 'Plantillas',
                icon: 'pi pi-fw pi-file',
                items: [
                    {
                        permiso: 'plantilla.create',
                        label: 'Crear Plantilla',
                        icon: 'pi pi-fw pi-file',
                        to: '/plantillas/crear-base',
                    },
                    {
                        permiso: 'plantilla.list',
                        label: 'Lista de Plantillas',
                        icon: 'pi pi-fw pi-list',
                        to: '/plantillas/lista-base',
                    },
                    {
                        permiso: 'plantilla.list',
                        label: 'Firmas',
                        icon: 'pi pi-fw pi-id-card',
                        to: '/firmas/lista',
                    },
                ],
            },

            {
                permiso: 'inscripcion.list',
                label: 'Inscripciones',
                icon: 'pi pi-fw pi-database',
                to: '/inscripciones/lista',
            },

            {
                permiso: 'inscripcion.list',
                label: 'Inscritos por Programa',
                icon: 'pi pi-fw pi-users',
                to: '/inscripciones/lista-programa',
            },

            // {
            // 	label: 'Nuevo Despósito', icon: 'pi pi-fw pi-plus-circle', to: '/pagos/nuevo-desposito'
            // },
            {
                permiso: 'pago.list',
                label: 'Pagos',
                icon: 'pi pi-fw pi-inbox',
                to: '/pagos/lista',
            },
            // {
            // 	permiso: 'pago.create',
            // 	label: 'Registrar Pago Devido', icon: 'pi pi-fw pi-inbox', to: '/pagos/efectivo/registro-comprobante'
            // },
            {
                permiso: 'pago.create',
                label: 'Registrar Comprobante Efectivo',
                icon: 'pi pi-fw pi-inbox',
                to: '/pagos/efectivo/registro-comprobante',
            },
            // {
            // 	permiso: 'pago.list',
            // 	label: 'Otros Despósitos', icon: 'pi pi-fw pi-inbox', to: '/pagos/otros-depositos'
            // },

            {
                permiso: 'reporte.no-adeudo',
                label: 'No Adeudo',
                icon: 'pi pi-fw pi-file-export',
                to: '/pagos/no-adeudo',
            },
            {
                permiso: 'reporte.pagos-realizados',
                label: 'Pagos por Cursante',
                icon: 'pi pi-fw pi-file-export',
                to: '/pagos/pagos-estudiante',
            },
            {
                permiso: 'reporte.consultar-deuda',
                label: 'Reportes de Deuda por Programa',
                icon: 'pi pi-fw pi-file-export',
                to: '/pagos/pagos-estudiante-programa',
            },
            {
                permiso: 'pago.asignar-multa',
                label: 'Asignar Multa',
                icon: 'pi pi-fw pi-file',
                to: '/multas/asignar-multa',
            },
            {
                permiso: 'estudiante.list',
                label: 'Cursantes',
                icon: 'pi pi-fw pi-users',
                to: '/cursantes',
            },
            // {
            //     permiso: 'panel.menu',
            //     label: 'Postulación Docentes',
            //     icon: 'pi pi-fw pi-briefcase',
            //     to: '/postulacion-docentes',
            // },
            {
                permiso: 'catalogo.menu',
                label: 'Catálogos',
                icon: 'pi pi-fw pi-book',
                items: [
                    {
                        permiso: 'destinatario.list',
                        label: 'Destinatarios',
                        icon: 'pi pi-fw pi-users',
                        to: '/destinatarios',
                    },
                    {
                        permiso: 'requisito.list',
                        label: 'Requisitos Generales',
                        icon: 'pi pi-fw pi-check',
                        to: '/requisitos',
                    },
                    {
                        permiso: 'requisito-destinatario.list',
                        label: 'Requisitos por Participante',
                        icon: 'pi pi-fw pi-check',
                        to: '/requisito-destinatarios',
                    },
                    {
                        permiso: 'concepto-pago.list',
                        label: 'Conceptos Pago',
                        icon: 'pi pi-fw pi-cog',
                        to: '/concepto-pago',
                    },
                    {
                        permiso: 'convenio.list',
                        label: 'Convenios',
                        icon: 'pi pi-fw pi-file',
                        to: '/convenios',
                    },
                    {
                        permiso: 'sede.list',
                        label: 'Sedes',
                        icon: 'pi pi-fw pi-map',
                        to: '/sedes',
                    },
                    {
                        permiso: 'facultad.list',
                        label: 'Facultades',
                        icon: 'pi pi-fw pi-building',
                        to: '/facultades',
                    },
                    {
                        permiso: 'universidad.list',
                        label: 'Universidades',
                        icon: 'pi pi-fw pi-building',
                        to: '/universidades',
                    },
                    {
                        permiso: 'universidad.list',
                        label: 'Grados Académicos',
                        icon: 'pi pi-fw pi-file',
                        to: '/grados-academicos',
                    },
                    {
                        permiso: 'grado-academico.list',
                        label: 'Niveles Académicos',
                        icon: 'pi pi-fw pi-file',
                        to: '/niveles-academicos',
                    },
                    {
                        permiso: 'estado.list',
                        label: 'Estados',
                        icon: 'pi pi-fw pi-list',
                        to: '/estados',
                    },
                ],
            },

            {
                permiso: 'configuracion.menu',
                label: 'Configuraciones',
                icon: 'pi pi-fw pi-cog',
                items: [
                    {
                        permiso: 'user.list',
                        label: 'Usuarios',
                        icon: 'pi pi-fw pi-users',
                        to: '/users',
                    },
                    {
                        permiso: 'role.list',
                        label: 'Roles y Permisos',
                        icon: 'pi pi-fw pi-lock',
                        to: '/roles-permisos',
                    },
                ],
            },
        ];
        const itemsDocentes = [
            {
                permiso: 'panel.menu',
                label: 'Postulación Docentes',
                icon: 'pi pi-fw pi-users',
                to: '/postulacion-docentes',
            },
            {
                permiso: 'panel.menu',
                label: 'Docentes',
                icon: 'pi pi-fw pi-user',
                to: '/programa/docentes',
            },
            {
                permiso: 'panel.menu',
                label: 'Encargados de Programa',
                icon: 'pi pi-fw pi-users',
                to: '/encargados-programa',
            },
            {
                permiso: 'panel.menu',
                label: 'Apoyo Logístico',
                icon: 'pi pi-fw pi-users',
                to: '/apoyo-logistico',
            },
            {
                permiso: 'panel.menu',
                label: 'Modulos',
                icon: 'pi pi-fw pi-list',
                to: '/modulos',
            },
            {
                permiso: 'panel.menu',
                label: 'Estudiantes',
                icon: 'pi pi-fw pi-user',
                to: '/estudiantes',
            },
            {
                permiso: 'plantilla.menu',
                label: 'Plantillas',
                icon: 'pi pi-fw pi-file',
                items: [
                    {
                        permiso: 'plantilla.create',
                        label: 'Crear Plantilla',
                        icon: 'pi pi-fw pi-file',
                        to: '/plantillas/crear-base',
                    },
                    {
                        permiso: 'plantilla.list',
                        label: 'Lista de Plantillas',
                        icon: 'pi pi-fw pi-list',
                        to: '/plantillas/lista-base',
                    },
                    {
                        permiso: 'plantilla.list',
                        label: 'Firmas',
                        icon: 'pi pi-fw pi-id-card',
                        to: '/firmas/lista',
                    },
                ],
            },
        ];
        return {
            programaId: null,
            nombrePrograma: null,
            layoutMode: 'static',
            profileMode: 'top',
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            rotateMenuButton: false,
            menuAdministrativo: [
                {
                    permiso: 'menu',
                    label: 'Menu',
                    icon: 'pi pi-fw pi-home',
                    items: itemsAdmin,
                },
            ],
            menuDocentes: [
                {
                    permiso: 'menu',
                    label: 'Menu',
                    icon: 'pi pi-fw pi-home',
                    items: itemsDocentes,
                },
            ],

            menuActive: false,
            profileExpanded: false,
            themeColors: [
                { name: 'Blue', file: 'blue', color: '#18318B' },
                { name: 'Indigo', file: 'indigo', color: '#6610f2' },
                { name: 'Purple', file: 'purple', color: '#6f42c1' },
                { name: 'Pink', file: 'pink', color: '#d63384' },
                { name: 'Orange', file: 'orange', color: '#fd7e14' },
                { name: 'Yellow', file: 'yellow', color: '#ffc107' },
                { name: 'Green', file: 'green', color: '#198754' },
                { name: 'Teal', file: 'teal', color: '#20c997' },
                { name: 'Cyan', file: 'cyan', color: '#0dcaf0' },
            ],
            layoutColors: [
                { name: 'Blue', file: 'blue', color: '#0d6efd' },
                { name: 'Indigo', file: 'indigo', color: '#6610f2' },
                { name: 'Purple', file: 'purple', color: '#6f42c1' },
                { name: 'Pink', file: 'pink', color: '#d63384' },
                { name: 'Orange', file: 'orange', color: '#fd7e14' },
                { name: 'Yellow', file: 'yellow', color: '#ffc107' },
                { name: 'Green', file: 'green', color: '#198754' },
                { name: 'Teal', file: 'teal', color: '#20c997' },
                { name: 'Cyan', file: 'cyan', color: '#0dcaf0' },
            ],
        };
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
    },
    created() {
        // Obtén el store
        const authStore = useAuthStore();

        // Sincroniza la variable local con el estado inicial del store
        this.programaId = authStore.getProgramaId;
        this.nombrePrograma = authStore.getNombrePrograma;
        console.log();
        // Usa `subscribe` para escuchar cambios en el estado
        this.unsubscribe = authStore.$subscribe((mutation, state) => {
            console.log('Mutation:', mutation); // Detalles de la mutación
            console.log('Nuevo estado:', state); // Estado completo actualizado

            // Actualiza la variable local con el nuevo valor
            this.programaId = state.programaId;
            this.nombrePrograma = state.nombrePrograma;
        });
    },
    beforeUnmount() {
        // Limpia la suscripción cuando el componente se destruya
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    },
    methods: {
        onDocumentClick() {
            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            if (!this.menuClick) {
                if (this.isHorizontal() || this.isSlim()) {
                    this.menuActive = false;
                    EventBus.emit('reset-active-index');
                }

                this.hideOverlayMenu();
            }

            this.topbarItemClick = false;
            this.menuClick = false;
        },
        onProfileClick(event) {
            this.profileExpanded = !this.profileExpanded;
            if (this.isHorizontal() || this.isSlim()) {
                EventBus.emit('reset-active-index');
            }

            event.preventDefault();
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (!event.item.items) {
                EventBus.emit('reset-active-index');
                this.hideOverlayMenu();
            }
            if (!event.item.items && this.isHorizontal()) {
                this.menuActive = false;
            }
        },
        onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },
        onMenuButtonClick(event) {
            this.menuClick = true;

            this.rotateMenuButton = !this.rotateMenuButton;
            this.topbarMenuActive = false;

            if (this.layoutMode === 'overlay') {
                this.overlayMenuActive = !this.overlayMenuActive;
            } else {
                if (this.isDesktop()) this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
                else this.staticMenuMobileActive = !this.staticMenuMobileActive;
            }

            event.preventDefault();
        },
        onTopbarMenuButtonClick(event) {
            this.topbarItemClick = true;
            this.topbarMenuActive = !this.topbarMenuActive;
            this.hideOverlayMenu();
            event.preventDefault();
        },
        onTopbarItemClick(event) {
            this.topbarItemClick = true;

            if (this.activeTopbarItem === event.item) this.activeTopbarItem = null;
            else this.activeTopbarItem = event.item;

            event.originalEvent.preventDefault();
        },
        hideOverlayMenu() {
            this.rotateMenuButton = false;
            this.overlayMenuActive = false;
            this.staticMenuMobileActive = false;
        },
        isDesktop() {
            return window.innerWidth > 1024;
        },
        isHorizontal() {
            return this.layoutMode === 'horizontal';
        },
        isSlim() {
            return this.layoutMode === 'slim';
        },
        isMenuVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static') return !this.staticMenuDesktopInactive;
                else if (this.layoutMode === 'overlay') return this.overlayMenuActive;
                else return true;
            } else {
                if (this.staticMenuMobileActive) return true;
                else if (this.layoutMode === 'overlay') return this.overlayMenuActive;
                else return true;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
            this.staticMenuDesktopInactive = false;
            this.overlayMenuActive = false;

            if (this.isHorizontal()) {
                this.profileMode = 'top';
                this.profileMenuActive = false;
            }
        },
        onMenuColorChange(menuColor) {
            this.$emit('menu-color-change', menuColor);
        },
        changeLayoutScheme(scheme) {
            this.$emit('layout-scheme-change', scheme);
        },
        onProfileModeChange(profileMode) {
            this.profileMode = profileMode;
        },
        onLayoutColorChange(layout) {
            this.$emit('layout-change', layout);
        },
        onThemeChange(theme) {
            this.$emit('theme-change', theme);
        },

        volverMenuAdministrativo(){
            useAuthStore().volverMenuAdministrativo()
        }
    },
    computed: {
        containerClass() {
            return [
                'layout-wrapper',
                {
                    'menu-layout-static': this.layoutMode !== 'overlay',
                    'menu-layout-overlay': this.layoutMode === 'overlay',
                    'layout-menu-overlay-active': this.overlayMenuActive,
                    'menu-layout-slim': this.layoutMode === 'slim',
                    'menu-layout-horizontal': this.layoutMode === 'horizontal',
                    'layout-menu-static-inactive': this.staticMenuDesktopInactive,
                    'layout-menu-static-active': this.staticMenuMobileActive,
                    'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                    'p-ripple-disabled': this.$primevue.config.ripple === false,
                },
            ];
        },
        menuClass() {
            return ['layout-menu-container', { 'layout-menu-dark': this.darkMenu }];
        },
    },
    components: {
        AppTopBar: AppTopBar,
        // 'AppConfig': AppConfig,
        AppInlineProfile: AppInlineProfile,
        AppMenu: AppMenu,
        AppFooter: AppFooter,
        Button: Button,
    },
};
</script>

<style lang="scss">
@import './App.scss';
</style>
