import {createRouter, createWebHistory} from 'vue-router';
import { isAuthenticatedGuard } from './authGuard';

const routes= [
	{
		path: '/',
		component: () => import('../components/Dashboard.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},

	{
		path: '/programas/registrar',
		name: 'registrar-programa',
		component: () => import('../modules/programas/FormPrograma.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/programas/editar/:id',
		name: 'editar-programa',
		component: () => import('../modules/programas/FormPrograma.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/programas/lista',
		name: 'lista-programa',
		component: () => import('../modules/programas/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/plantillas/crear/:programaId',
		name: 'crear-plantilla',
		component: () => import('../modules/plantillas/FormPlantillaPrograma.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/plantillas/editar/:id/:programaId',
		name: 'editar-plantilla',
		component: () => import('../modules/plantillas/FormPlantillaPrograma.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/plantillas-docentes/crear/:tipoPlantilla/:docenteAsignaturaId', // Registro Docente es el id del docente asignatura o la carta de invitacion o contrato docente
		name: 'crear-plantilla-docente',
		component: () => import('../modules/plantillas/FormPlantillaDocentes.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/plantillas-docentes/editar/:tipoPlantilla/:docenteAsignaturaId/:plantillaId', // Registro Docente es el id del docente asignatura o la carta de invitacion o contrato docente
		name: 'editar-plantilla-docente',
		component: () => import('../modules/plantillas/FormPlantillaDocentes.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/plantillas-descuento/crear/:programaId/:programaDescuentoId',
		name: 'crear-plantilla-descuento',
		component: () => import('../modules/plantillas/FormPlantillaDescuento.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/plantillas-descuento/editar/:id/:programaId/:programaDescuentoId',
		name: 'editar-plantilla-descuento',
		component: () => import('../modules/plantillas/FormPlantillaDescuento.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	
	{
		path: '/plantillas/crear-base',
		name: 'crear-plantilla-base',
		component: () => import('../modules/plantillas/FormPlantilla.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/plantillas/editar-base/:id',
		name: 'editar-plantilla-base',
		component: () => import('../modules/plantillas/FormPlantilla.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/plantillas/lista-base',
		name: 'lista-plantilla-base',
		component: () => import('../modules/plantillas/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},

	{
		path: '/firmas/lista',
		name: 'lista-firma',
		component: () => import('../modules/firmas/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/firmas/crear',
		name: 'crear-firma',
		component: () => import('../modules/firmas/FormFirma.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/firmas/editar/:id',
		name: 'editar-firma',
		component: () => import('../modules/firmas/FormFirma.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},

	{
		path: '/inscripciones/lista',
		name: 'lista-inscripciones',
		component: () => import('../modules/inscripcion/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},

	{
		path: '/inscripciones/lista-programa',
		name: 'lista-inscripciones-programa',
		component: () => import('../modules/inscripcion/ListaPrograma.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},

	// {
	// 	path: '/plantillas/editar/:id',
	// 	name: 'editar-plantilla',
	// 	component: () => import('../modules/plantillas/FormPlantilla.vue'),
	// 	beforeEnter: [isAuthenticatedGuard],
	// },

	{
		path: '/pagos/lista',
		name: 'pagos-lista',
		component: () => import('../modules/pagos/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/pagos/efectivo/registro-comprobante',
		name: 'pagos-efectivo-registro-comprobante',
		component: () => import('../modules/pagos/ListaEfectivo.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/pagos/no-adeudo',
		name: 'no-adeudo',
		component: () => import('../modules/pagos/FormNoAdeudo.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},	
	{
		path: '/pagos/pagos-estudiante',
		name: 'pagos-estudiante',
		component: () => import('../modules/pagos/FormPagosEstudiante.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/multas/asignar-multa',
		name: 'asignar-multa',
		component: () => import('../modules/multas/FormAsignarMulta.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	
	// cursantes
	{
		path: '/cursantes',
		name: 'cursantes',
		component: () => import('../modules/cursantes/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},

	{
		path: '/pagos/pagos-estudiante-programa',
		name: 'pagos-estudiante-programa',
		component: () => import('../modules/pagos/FormPagosEstudiantePrograma.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},

	// postulacion docencia
	{
		path: '/postulacion-docentes',
		name: 'postulacion-docentes',
		component: () => import('../modules/docentes/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/postulacion-docentes/detalle/:id',
		name: 'detalle-postulacion-docentes',
		component: () => import('../modules/docentes/Detalle.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/postulacion-docentes/registrar',
		name: 'registrar-postulacion-docentes',
		component: () => import('../modules/docentes/Registrar.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/declaracion-jurada/editar/:id',
		component: () => import('../modules/declaracion-jurada/EditarDeclaracionJurada.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/modulo/asignatura/:id',
		component: () => import('../modules/modulos/Asignatura.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/modulos',
		component: () => import('../modules/modulos/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/programa/docentes',
		component: () => import('../modules/programa-docentes/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/encargados-programa',
		component: () => import('../modules/encargados-programa/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/apoyo-logistico',
		component: () => import('../modules/apoyo-logistico/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/estudiantes',
		component: () => import('../modules/estudiantes/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	// Catálogos
	{
		path: '/facultades',
		name: 'facultades',
		component: () => import('../modules/facultades/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},

	{
		path: '/universidades',
		name: 'universidades',
		component: () => import('../modules/universidades/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/grados-academicos',
		name: 'grados-academicos',
		component: () => import('../modules/grados-academicos/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/niveles-academicos',
		name: 'niveles-academicos',
		component: () => import('../modules/niveles-academicos/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	// estados
	{
		path: '/estados',
		name: 'estados',
		component: () => import('../modules/estados/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},

	// destinatarios
	{
		path: '/destinatarios',
		name: 'destinatarios',
		component: () => import('../modules/destinatarios/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	// requisitos
	{
		path: '/requisitos',
		name: 'requisitos',
		component: () => import('../modules/requisitos/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	// requisito_destinatarios
	{
		path: '/requisito-destinatarios',
		name: 'requisito-destinatarios',
		component: () => import('../modules/requisito-destinatarios/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	// convenios
	{
		path: '/concepto-pago',
		name: 'concepto-pago',
		component: () => import('../modules/concepto-pago/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	// convenios
	{
		path: '/convenios',
		name: 'convenios',
		component: () => import('../modules/convenios/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	// sedes
	{
		path: '/sedes',
		name: 'sedes',
		component: () => import('../modules/sedes/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},

	// users
	{
		path: '/users',
		name: 'users',
		component: () => import('../modules/users/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	// roles y permisos
	{
		path: '/roles-permisos',
		name: 'roles-permisos',
		component: () => import('../modules/roles-permisos/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},

	{
		path: '/dashboards/generic',
		name: 'dashboards',
		component: () => import('../components/DashboardDemo.vue')
	},
	{
		path: '/dashboards/dashboard_banking',
		name: 'dashboard_banking',
		component: () => import('../components/DashboardBanking.vue')
	},
	{
		path: '/formlayout',
		name: 'formlayout',
		component: () => import('../components/FormLayoutDemo.vue')
	},
	
	{
		path: '/invalidstate',
		name: 'invalidstate',
		component: () => import('../components/InvalidStateDemo.vue')
	},
	{
		path: '/button',
		name: 'button',
		component: () => import('../components/ButtonDemo.vue')
	},
	{
		path: '/table',
		name: 'table',
		component: () => import('../components/TableDemo.vue')
	},
	{
		path: '/list',
		name: 'list',
		component: () => import('../components/ListDemo.vue')
	},
	{
		path: '/tree',
		name: 'tree',
		component: () => import('../components/TreeDemo.vue')
	},
	{
		path: '/panel',
		name: 'panel',
		component: () => import('../components/PanelsDemo.vue')
	},
	{
		path: '/overlay',
		name: 'overlay',
		component: () => import('../components/OverlaysDemo.vue')
	},
	{
		path: '/media',
		name: 'media',
		component: () => import('../components/MediaDemo.vue')
	},
	{
		path: '/menus',
		component: () => import('../components/MenusDemo.vue'),
		children: [{
			path: '',
			component: () => import('../components/menu/PersonalDemo.vue')
		},
			{
				path: '/menus/seat',
				component: () => import('../components/menu/SeatDemo.vue')
			},
			{
				path: '/menus/payment',
				component: () => import('../components/menu/PaymentDemo.vue')
			},
			{
				path: '/menus/confirmation',
				component: () => import('../components/menu/ConfirmationDemo.vue')
			}]
	},
	{
		path: '/messages',
		name: 'messages',
		component: () => import('../components/MessagesDemo.vue')
	},
	{
		path: '/file',
		name: 'file',
		component: () => import('../components/FileDemo.vue')
	},
	{
		path: '/chart',
		name: 'chart',
		component: () => import('../components/ChartsDemo.vue')
	},
	{
		path: '/misc',
		name: 'misc',
		component: () => import('../components/MiscDemo.vue')
	},
	{
		path: '/icons',
		name: 'icons',
		component: () => import('../utilities/Icons.vue')
	},
	{
		path: '/empty',
		name: 'empty',
		component: () => import('../components/EmptyPage.vue')
	},
	{
		path: '/crud',
		name: 'crud',
		component: () => import('../pages/CrudDemo.vue')
	},
	{
		path: '/calendar',
		name: 'calendar',
		component: () => import('../pages/CalendarDemo.vue')
	},
	{
        path: '/timeline',
        name: 'timeline',
        component: () => import('../pages/TimelineDemo.vue')
    },
	{
		path: '/invoice',
		name: 'invoice',
		component: () => import('../pages/Invoice.vue')
	},
	{
		path: '/help',
		name: 'help',
		component: () => import('../pages/Help.vue')
	},
	{
		path: '/documentation',
		name: 'documentation',
		component: () => import('../components/Documentation.vue')
	},
	{
        path: '/login',
        name: 'login',
        component: () => import('../pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('../pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('../pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('../pages/Access.vue')
	},
	{
		path: '/wizard',
		name: 'wizard',
		component: () => import('../pages/Wizard.vue'),
    },
	{
        path: '/blocks',
        name: 'blocks',
        component: () => import('../components/BlocksDemo.vue')
    },
	{
        path: '/:pathMatch(.*)*',
        redirect: { name: 'login' }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
	scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

export default router;